import React, { useState, useMemo, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { create, getDealerTypes } from 'actions/admin/dealer';
import Errors from 'views/Notifications/Errors';
import Spinner from 'views/Spinner';
import ReactTooltip from 'react-tooltip';
import { PASSWORD_DEFAULT_LENGTH } from 'constants/index';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  InputGroup,
  //InputGroupAddon,
  InputGroupText,
  FormText
} from 'reactstrap';
import { REMOVE_ERRORS, SET_GOOGLE_ADDRESS_FORM_DATA } from 'actions/types';
import Autocomplete from 'react-google-autocomplete';
import { GOOGLE_MAPS_API_KEY } from '../../../config';
import { setGoogleAddressFormData } from 'actions/commonActions';
import 'react-phone-number-input/style.css';
import PhoneInput, {
  getCountryCallingCode,
  isValidPhoneNumber,
} from 'react-phone-number-input';
import extractNumber from 'utils/extractNumber';
import { getCountryShort } from 'utils/countryCode';
import { FaTiktok } from 'react-icons/fa';

const CreateDealer = ({
  create,
  history,
  errorList,
  loading,
  dealerTypeList,
  getDealerTypes,
  googleAddressFormData,
  setGoogleAddressFormData,
}) => {
  /********************************generate password ********************/

  //   randomstring.generate(PASSWORD_DEFAULT_LENGTH)
  // );
  const password_str = Math.random()
    .toString(36)
    .substr(2, PASSWORD_DEFAULT_LENGTH);

  /********************************usestate form data ********************/
  let navigate = useNavigate();
  const inputRef = useRef(null);
  const [defaultCountry, setDefaultCountry] = useState('US');
  const [defaultCountryCode, setDefaultCountryCode] = useState('+1');
  const [checkPassword, setCheckPassword] = useState(false);
  const [validated, setValidated] = useState(false);
  const [formData, setFormData] = useState({
    first_name: '',
    last_name: '',
    email: '',
    business_name: '',
    business_email: '',
    // ccode: '+1',
    contact_no: '',
    iute164_phone: '',
    website: '',
    business_license_no: '',
    business_model_description: '',
    annual_sales_volume: '',
    shaft_clubhead_component_vendors: '',
    dealer_type_id: '',
    is_featured: '',
    // is_published: '',
    password: password_str,
    selectedFile: '',
    image: '',
    address: '',
    latitute: 0,
    longitude: 0,
    address_line_1: '',
    address_line_2: '',
    google_map_link: '',
    fitting_studio: false,
    city: '',
    zipcode: '',
    country: '',
    state: '',
    google_id: '',
    google_place_id: '',
    country_code: '',
    facebook: '',
    instagram: '',
    // linkedIn: '',
    twitter: '',
    youtube: '',
    snapchat: '',
    tiktok: '',
  });


  useEffect(() => {
    const fetchDefaultCountryCode = async () => {
      try {
        const response = await fetch('https://geolocation-db.com/json/');
        const data = await response.json();
        const countryCode = data.country_code || '';

        // Use libphonenumber-js to format the country code
        const phoneNumber = getCountryCallingCode(countryCode);


        if (phoneNumber) {
          setDefaultCountry(countryCode);
          setFormData({ ...formData, ccode: `+${phoneNumber}` });
          setDefaultCountryCode(`+${phoneNumber}`);

        }
      } catch (error) {
        console.error('Error fetching default country code:', error);
      }
    };

    fetchDefaultCountryCode();
  }, []);

  /************************explode form data ********************/
  const {
    first_name,
    last_name,
    email,
    dealer_type_id,
    business_name,
    business_email,
    // ccode,
    contact_no,
    iute164_phone,
    website,
    business_license_no,
    business_model_description,
    annual_sales_volume,
    shaft_clubhead_component_vendors,
    password,
    selectedFile,
    image,
    address,
    latitute,
    longitude,
    google_map_link,
    fitting_studio,
    address_line_1,
    address_line_2,
    city,
    zipcode,
    country,
    state,
    facebook,
    instagram,
    // linkedIn,
    twitter,
    youtube,
    snapchat,
    tiktok,
  } = formData;



  /***********************get dealer type ********************/
  useMemo(() => {
    getDealerTypes();
  }, [getDealerTypes]);

  /***********************use dispatch ********************/
  const dispatch = useDispatch();

  const [autocompleteValue, setAutocompleteValue] = useState('');
  /***********************on change event ********************/
  const onChange = (e) => {
    if (e.target.name === 'thumbnail') {
      let image_file = e.target.files[0];

      let image_url = URL.createObjectURL(image_file);
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
        selectedFile: image_file,
        image: image_url,
      });
    } else if (e.target.name === 'business_license_no') {
      setFormData({ ...formData, business_license_no: e.target.value.replace(/[&#<>@]/g, '') })
    } else {
      // setFormData({ ...formData, [e.target.name]: e.target.value });
      const newFormData = { ...formData, [e.target.name]: e.target.value };
      newFormData['address'] = `${newFormData.address_line_1} ${newFormData.address_line_2 ? newFormData.address_line_2 + ',' : ''
        } ${newFormData.city} ${newFormData.state} ${newFormData.zipcode} ${newFormData.country
        }`;
      setFormData(newFormData);
      setCheckPassword(false);
    }
    dispatch({ type: REMOVE_ERRORS });
  };

  const onFittingStudioChange = (e) => {
    setFormData({ ...formData, fitting_studio: e.target.checked });
  };

  //########################## change event for phone number ##############################
  const onPhoneChange = (number) => {
    const contact_no = extractNumber(number, defaultCountryCode);
    setFormData((form) => ({
      ...form,
      contact_no: contact_no,
      iute164_phone: number ?? '',
    }));
  };

  const onCountryChange = (code) => {
    setFormData((form) => ({
      ...form,
      ccode: '+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)),
    }));
    setDefaultCountryCode('+' + getCountryCallingCode(code ?? getCountryShort(defaultCountryCode)));
  };

  useEffect(() => {
    ReactTooltip.show(inputRef.current);
  }, []);

  //#################### update formdata after changes in google address  #################
  useEffect(() => {
    if (Object.keys(googleAddressFormData).length > 0) {
      setFormData({
        ...formData,
        // ...googleAddressFormData,
        first_name: googleAddressFormData.first_name,
        last_name: googleAddressFormData.last_name,
        email: googleAddressFormData.email,
        business_name: googleAddressFormData.business_name,
        business_email: googleAddressFormData.business_email,
        password: googleAddressFormData.password,
        confirm_password: googleAddressFormData.confirm_password,
        contact_no: googleAddressFormData.contact_no,
        ccode: googleAddressFormData.ccode,
        iute164_phone: googleAddressFormData.iute164_phone,
        selectedFile: googleAddressFormData.selectedFile,
        image: googleAddressFormData.image,
        website: googleAddressFormData.website,
        business_license_no: googleAddressFormData.business_license_no,
        business_model_description:
          googleAddressFormData.business_model_description,
        annual_sales_volume: googleAddressFormData.annual_sales_volume,
        shaft_clubhead_component_vendors:
          googleAddressFormData.shaft_clubhead_component_vendors,
        dealer_type_id: googleAddressFormData.dealer_type_id,
        facebook: googleAddressFormData.facebook,
        instagram: googleAddressFormData.instagram,
        // linkedIn,
        twitter: googleAddressFormData.twitter,
        youtube: googleAddressFormData.youtube,
        snapchat: googleAddressFormData.snapchat,
        tiktok: googleAddressFormData.tiktok,
      });
    }
    dispatch({
      type: SET_GOOGLE_ADDRESS_FORM_DATA,
      payload: {},
    });
  }, [address]);

  const setGMapPlace = (location) => {
    const google_id = location.id;
    const google_place_id = location.place_id;
    const address = location.formatted_address;
    const addresses = location.address_components;
    let latitute = location?.geometry?.location?.lat((e) => e.Scopes[0].e);
    let longitude = location?.geometry?.location?.lng((e) => e.Scopes[0].f);

    let city = '';
    let state = '';
    let address_line_1 = '';
    let address_line_2 = '';
    let country = '';
    let country_code = '';
    let zipcode = '';
    if (addresses)
      addresses.forEach((e) => {
        switch (e.types[0]) {
          case 'street_number':
          case 'route':
            address_line_1 += (address_line_1 ? ' ' : '') + e.long_name;

            break;
          case 'sublocality_level_2':
          case 'sublocality_level_1':
            address_line_2 += (address_line_2 ? ' ' : '') + e.long_name;
            break;
          case 'locality':
          case 'sublocality_level_1':
          case 'administrative_area_level_3':
          case 'administrative_area_level_2':
          case 'postal_town':
            if (city == '') {
              city = e.long_name;
            }
            break;
          case 'administrative_area_level_1':
            state = e.long_name;
            break;
          case 'postal_code':
          case 'postal_code_prefix':
            zipcode = e.long_name;
            break;
          case 'country':
            country = e.long_name;
            country_code = e.short_name;
            break;
          default:
            break;
        }
      });
    setFormData({
      ...formData,
      address,
      city,
      latitute,
      longitude,
      address_line_1,
      address_line_2,
      google_id,
      google_place_id,
      state,
      country,
      country_code,
      zipcode,
      // location: geometry,
    });
    setAutocompleteValue('');
  };
  const handleInputChange = (event) => {
    setAutocompleteValue(event.target.value);
  };

  //remove file
  var removeFile = () => () => {
    document.getElementById('thumbnail').value = '';
    setFormData({
      ...formData,
      image: '',
      selectedFile: '',
    });
  };

  /***********************on submit event ********************/
  const onSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;

    // if (form.checkValidity() === false) {
    //   setValidated(true);
    //   return false;
    // }

    // if (form.checkValidity()) {
    setValidated(false);
    console.log(formData, 'ffffffffffffff')
    setFormData({
      ...formData,
    });
    Object.keys(formData).forEach((k) => {
      typeof formData[k] === 'string'
        ? (formData[k] = formData[k]?.trim())
        : (formData[k] = formData[k]);
    });
    const regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[^\w\s]).{8,}$/
    const isChecked = regex.test(password);
    console.log(isChecked, 'isChecked')
    if (isChecked) {
      create({ ...formData, ccode: defaultCountryCode }, navigate);
      setCheckPassword(false);
    } else {
      setCheckPassword(true);
    }
    // }
  };
  console.log(checkPassword, 'check')
  return (
    <>
      <div className="animated fadeIn">
        {loading ? (
          <Spinner />
        ) : (
          <Form
            // noValidate
            // validated={validated}
            className="form-horizontal"
            autoComplete="false"
            onSubmit={(e) => onSubmit(e)}
          >
            <Row>
              <Col xs="12" md="12">
                <Card>
                  <CardHeader>
                    <h5>Owner Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="first_name">
                            First Name <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="first_name"
                            name="first_name"
                            maxLength="25"
                            value={first_name}
                            onChange={(e) => onChange(e)}
                            invalid={
                              first_name == '' && validated ? true : false
                            }
                          />
                          <Errors current_key="first_name" key="first_name" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="last_name">Last Name</Label>
                          <Input
                            type="text"
                            id="last_name"
                            name="last_name"
                            maxLength="25"
                            value={last_name}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.last_name ? true : false}
                          />
                          <Errors current_key="last_name" key="last_name" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="email">
                            Email <span>*</span>
                          </Label>
                          <Input
                            type="email"
                            id="email"
                            name="email"
                            maxLength="50"
                            value={email}
                            onChange={(e) => onChange(e)}
                            invalid={email == '' && validated ? true : false}
                          />
                          <Errors current_key="email" key="email" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="password">
                            Password <span>*</span>
                          </Label>
                          {password == '' &&
                            <ReactTooltip id="title required" efect="solid" place="bottom">
                              Password must contain at least 8 characters, including Upper/lowercase, numbers and special characters
                            </ReactTooltip>
                          }
                          <Input
                            type="text"
                            id="password"
                            name="password"
                            ref={inputRef}
                            data-tip
                            data-for="title required"
                            value={password}
                            onChange={(e) => onChange(e)}
                            invalid={password == '' && validated ? true : false}
                          />
                          <Errors current_key="password" key="password" />
                          {(checkPassword && password != "") &&
                            <div id="message">
                              <FormText style={{ fontSize: "10px" }} className='text-danger'> Password requirements:</FormText>
                              <FormText>
                                <li style={{ fontSize: "10px" }} className='text-danger'>Must contain at least 8 characters</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 uppercase letter</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 number</li>
                                <li style={{ fontSize: "10px" }} className='text-danger'>At least 1 special character (@$!%*#?&.)</li>
                              </FormText>
                            </div>
                          }
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="dealer_type_id">
                            {' '}
                            Dealer Type <span>*</span>
                          </Label>
                          <Input
                            type="select"
                            name="dealer_type_id"
                            id="dealer_type_id"
                            value={dealer_type_id}
                            onChange={(e) => onChange(e)}
                            invalid={
                              dealer_type_id == '' && validated ? true : false
                            }
                          >
                            <option value={''} disabled>
                              {' '}
                              {'Select'}{' '}
                            </option>
                            {dealerTypeList
                              ? dealerTypeList.map((fieldData, index) => {
                                return (
                                  <option key={index} value={fieldData._id}>
                                    {fieldData.name}
                                  </option>
                                );
                              })
                              : ''}
                          </Input>
                          <Errors
                            current_key="dealer_type_id"
                            key="dealer_type_id"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
                <Card>
                  <CardHeader>
                    <h5>Business Information</h5>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="business_name">
                            Business Name <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="business_name"
                            name="business_name"
                            maxLength="100"
                            value={business_name}
                            onChange={(e) => onChange(e)}
                            invalid={
                              business_name == '' && validated ? true : false
                            }
                          />
                          <Errors
                            current_key="business_name"
                            key="business_name"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="business_email">
                            Business Email <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="business_email"
                            name="business_email"
                            maxLength="100"
                            value={business_email}
                            onChange={(e) => onChange(e)}
                            invalid={
                              business_email == '' && validated ? true : false
                            }
                          />
                          <Errors
                            current_key="business_email"
                            key="business_email"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="address">Search Your Address</Label>
                          <Autocomplete
                            apiKey={GOOGLE_MAPS_API_KEY}
                            onPlaceSelected={(place) => {
                              {
                                setGMapPlace(place);
                              }
                            }}
                            options={{
                              types: ['address'],
                            }}
                            value={autocompleteValue}
                            onChange={handleInputChange}
                            className="form-control"
                            onFocus={() => setGoogleAddressFormData(formData)}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="address">
                            Address Line 1<span>*</span>
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address_line_1"
                            name="address_line_1"
                            placeholder="Address Line 1 *"
                            maxLength="100"
                            value={address_line_1}
                            onChange={(e) => onChange(e)}
                            invalid={
                              address_line_1 == '' && validated ? true : false
                            }
                          />
                          <Errors
                            current_key="address_line_1"
                            key="address_line_1"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="address">Address Line 2</Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="address_line_2"
                            name="address_line_2"
                            placeholder="Address Line 2"
                            maxLength="100"
                            value={address_line_2}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.address_line_2 ? true : false}
                          />
                          <Errors
                            current_key="address_line_2"
                            key="address_line_2"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="country">
                            Country <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            name="country"
                            id="country"
                            value={country}
                            invalid={country == '' && validated ? true : false}
                          ></Input>
                          <Errors current_key="country" key="country" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="state">State <span>*</span></Label>
                          <Input
                            type="text"
                            name="state"
                            id="state"
                            value={state}
                            readOnly
                            invalid={errorList.state ? true : false}
                          />
                          <Errors current_key="state" key="state" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="city">
                            City <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="city"
                            name="city"
                            maxLength="100"
                            value={city}
                            invalid={city == '' && validated ? true : false}
                          />
                          <Errors current_key="city" key="city" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="zipcode">
                            Zip/Postal Code <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="zipcode"
                            name="zipcode"
                            maxLength="10"
                            // minLength="3"
                            value={zipcode}
                            onChange={(e) => onChange(e)}
                            invalid={zipcode == '' && validated ? true : false}
                          />
                          <Errors current_key="zipcode" key="zipcode" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="contact_no">
                            Contact Number <span>*</span>
                          </Label>
                          <PhoneInput
                            className=""
                            autoComplete="tel"
                            withCountryCallingCode={true}
                            countryCallingCodeEditable={false}
                            smartCaret={true}
                            international={true}
                            defaultCountry={defaultCountry}
                            value={iute164_phone}
                            name="contact_no"
                            addInternationalOption={false}
                            onChange={onPhoneChange}
                            onCountryChange={onCountryChange}
                          />
                          <Errors current_key="contact_no" key="contact_no" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="google_map_link">
                            Google Map Link
                          </Label>
                          <Input
                            type="text"
                            className="form-control"
                            id="google_map_link"
                            name="google_map_link"
                            value={google_map_link}
                            onChange={(e) => onChange(e)}
                            // disabled={true}
                            invalid={errorList.google_map_link ? true : false}
                          />
                          <Errors
                            current_key="google_map_link"
                            key="google_map_link"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup className="checkouter m-0">
                          <Label className="custom_check">
                            Fitting Studio
                            <Input
                              type="checkbox"
                              // style={{ transform: "scale(1.5)", marginLeft: "5px" }}
                              id="fitting_studio"
                              name="fitting_studio"
                              className="mr-2"
                              checked={fitting_studio}
                              onChange={onFittingStudioChange}
                              // disabled={true}
                              invalid={errorList.fitting_studio ? true : false}
                            />
                            <span className="check_indicator">&nbsp;</span>
                          </Label>
                          <Errors
                            current_key="fitting_studio"
                            key="fitting_studio"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="12">
                        <FormGroup>
                          <Label>Social media handles</Label>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="Facebook Link"
                              id="facebook"
                              name="facebook"
                              value={facebook}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.facebook ? true : false}
                            />

                            <InputGroupText>
                              <i className="fa fa-facebook"></i>
                            </InputGroupText>

                            <Errors current_key="facebook" key="facebook" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="Instagram Link"
                              id="instagram"
                              name="instagram"
                              value={instagram}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.instagram ? true : false}
                            />

                            <InputGroupText>
                              <i className="fa fa-instagram"></i>
                            </InputGroupText>

                            <Errors current_key="instagram" key="instagram" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="Twitter Link"
                              id="twitter"
                              name="twitter"
                              value={twitter}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.twitter ? true : false}
                            />

                            <InputGroupText>
                              <i className="fa fa-twitter"></i>
                            </InputGroupText>

                            <Errors current_key="twitter" key="twitter" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="YouTube Link"
                              id="youtube"
                              name="youtube"
                              value={youtube}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.youtube ? true : false}
                            />

                            <InputGroupText>
                              <i className="fa fa-youtube"></i>
                            </InputGroupText>

                            <Errors current_key="youtube" key="youtube" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="Snapchat Link"
                              id="snapchat"
                              name="snapchat"
                              value={snapchat}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.snapchat ? true : false}
                            />

                            <InputGroupText>
                              <i className="fa fa-snapchat"></i>
                            </InputGroupText>

                            <Errors current_key="snapchat" key="snapchat" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <InputGroup size="lg">
                            <Input
                              placeholder="Tiktok Link"
                              id="tiktok"
                              name="tiktok"
                              value={tiktok}
                              onChange={(e) => onChange(e)}
                              invalid={errorList.tiktok ? true : false}
                            />

                            <InputGroupText>
                              <FaTiktok />
                            </InputGroupText>

                            <Errors current_key="tiktok" key="tiktok" />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="thumbnail">Upload Logo</Label>
                          <Input
                            className="form-control"
                            type="file"
                            id="thumbnail"
                            name="thumbnail"
                            onChange={(e) => onChange(e)}
                            // multiple
                            invalid={errorList.image ? true : false}
                          />
                          {/* {image && <img src={image} className="preview-img" alt="" />} */}
                          {image && (
                            <div>
                              <img src={image} className="preview-img" alt="" />
                              <Button
                                title="Remove Image"
                                className="btn btn-sm btn-danger"
                                onClick={removeFile()}
                              >
                                <i className="fa fa-close"></i>
                              </Button>
                            </div>
                          )}
                          <Errors current_key="image" key="image" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="website">Website</Label>
                          <Input
                            type="text"
                            id="website"
                            name="website"
                            maxLength="100"
                            value={website}
                            onChange={(e) => onChange(e)}
                            invalid={errorList.website ? true : false}
                          />
                          <Errors current_key="website" key="website" />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="business_license_no">
                            Business License # or Tax I.D. Number <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="business_license_no"
                            name="business_license_no"
                            maxLength="100"
                            value={business_license_no}
                            onChange={(e) => onChange(e)}
                            invalid={
                              business_license_no == '' && validated
                                ? true
                                : false
                            }
                          />
                          <Errors
                            current_key="business_license_no"
                            key="business_license_no"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <Label htmlFor="business_model_description">
                            Description of your business model/plan{' '}
                            <span>*</span>
                          </Label>
                          <Input
                            type="textarea"
                            id="business_model_description"
                            name="business_model_description"
                            maxLength="100"
                            value={business_model_description}
                            onChange={(e) => onChange(e)}
                            invalid={
                              business_model_description == '' && validated
                                ? true
                                : false
                            }
                          />
                          <Errors
                            current_key="business_model_description"
                            key="business_model_description"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="annual_sales_volume">
                            Annual sales volume of metalwoods built or
                            retrofitted with premium shafts? <span>*</span>
                          </Label>
                          <Input
                            type="number"
                            id="annual_sales_volume"
                            name="annual_sales_volume"
                            min={0}
                            // maxLength="100"
                            value={annual_sales_volume}
                            onChange={(e) => onChange(e)}
                            invalid={
                              annual_sales_volume == '' && validated
                                ? true
                                : false
                            }
                          />
                          <Errors
                            current_key="annual_sales_volume"
                            key="annual_sales_volume"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="shaft_clubhead_component_vendors">
                            Primary component suppliers <span>*</span>
                          </Label>
                          <Input
                            type="text"
                            id="shaft_clubhead_component_vendors"
                            name="shaft_clubhead_component_vendors"
                            maxLength="100"
                            value={shaft_clubhead_component_vendors}
                            onChange={(e) => onChange(e)}
                            invalid={
                              shaft_clubhead_component_vendors == '' &&
                                validated
                                ? true
                                : false
                            }
                          />
                          <Errors
                            current_key="shaft_clubhead_component_vendors"
                            key="shaft_clubhead_component_vendors"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardFooter>
                    <Button
                      className="mr-05"
                      type="submit"
                      size="sm"
                      color="primary"
                      disabled={loading}
                    >
                      <i className="fa fa-dot-circle-o"></i> Submit
                    </Button>
                    <Link to="/admin/dealers">
                      <Button type="reset" size="sm" color="danger">
                        <i className="fa fa-ban"></i> Cancel
                      </Button>
                    </Link>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
          </Form>
        )}
      </div>
    </>
  );
};

CreateDealer.propTypes = {
  create: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  errorList: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  dealerTypeList: PropTypes.array.isRequired,
  getDealerTypes: PropTypes.func.isRequired,
  setGoogleAddressFormData: PropTypes.func.isRequired,
  googleAddressFormData: PropTypes.object.isRequired,
};
const mapStateToProps = (state) => ({
  user: state.user,
  loading: state.dealer.loading,
  errorList: state.errors,
  dealerTypeList: state.dealer.dealerTypeList,
  googleAddressFormData: state.commonActions.googleAddressFormData,
});

export default connect(mapStateToProps, {
  create,
  getDealerTypes,
  setGoogleAddressFormData,
})(CreateDealer);
